import { resolve as resolveExcReadyPromise } from './exc-ready-promise';
import { resolve as resolveShellMetricsPromise } from './shell-metrics-promise';
import {
  getIsInsideExperienceCloud,
  getIsUnifiedShellEnabled,
} from './unified-shell-toggles';
import createAndSetAcceptLanguageVariable from './util/acceptLanguageHeader'
import { getEimInstanceId } from './util/getEimInstanceId'
import { getText } from './util/localization';
import { logError } from './util/log';

export function initialize() {
  return Promise.resolve(getIsInsideExperienceCloud()).then(
    isInsideExperienceCloud => {
      if (isInsideExperienceCloud === false) {
        resolveShellMetricsPromise();
        return Promise.resolve();
      } else {
        return bootstrapUnifiedShell();
      }
    }
  );
}

function getCurrentUser() {
  return Promise.all([
    System.import('@wf-mfe/auth'),
    System.import('rxjs'),
  ]).then(([auth, operators]) =>
    auth.getCurrentUser$().pipe(operators.first()).toPromise()
  );
}

async function bootstrapUnifiedShell() {
  if (getIsUnifiedShellEnabled()) {
    logRumEvent('nwe_shell_bootstrap_start')
    const [excApp, page, userProfile] = await Promise.all(
      [
        '@adobe/exc-app',
        '@adobe/exc-app/page',
        '@adobe/exc-app/userprofile',
      ].map(mfeName => System.import(mfeName).then(module => module.default))
    );

    const runtime = excApp();

    // Handles history events and synchronizes the external url updates from
    // the Shell with the internal history.
    runtime.on('history', ({ type, path }) => {
      if (type !== 'external') {
        return;
      }

      const cleanedPath = path[0] === '/' ? path : '/' + path;

      if (!window.config?.isPolar) {
        System.import('@wf-mfe/navigation').then(({ navigate }) => {
          if (window.location.pathname !== cleanedPath) {
            navigate(cleanedPath, { replace: true });
          }
        });
      }
    });

    runtime.heroClick = () => launchToLandingPage();

    // Syncs title changes from WF to the title used by ExC
    new MutationObserver(function (mutations) {
      const changedTitle = mutations[0].target.innerText;
      if (changedTitle) {
        runtime.title = changedTitle;
      }
    }).observe(document.querySelector('title'), {
      subtree: true,
      characterData: true,
      childList: true,
    });

    const waitingInterval = setInterval(() => {
      logRumEvent('nwe_shell_waiting')
    }, 30e3)

    // Handles the ready event and can use the provided data.
    runtime.on('ready', ({ locale, localeOriginal }) => {
      clearInterval(waitingInterval)

      resolveExcReadyPromise();
      getText('workfrontprofile', 'Workfront Profile').then(wfProfileMsg => {
        userProfile.setButtons([
          {
            callback: () => launchUserProfile(),
            id: 'workfront_profile',
            label: wfProfileMsg,
          },
        ]);
      });

      if (locale || localeOriginal) {
        const targetLocale = localeOriginal ?? locale;
        document.documentElement.setAttribute('lang', targetLocale);
        createAndSetAcceptLanguageVariable(locale)
        getCurrentUser().then(user => {
          const wfLocale = user.locale.replace('_', '-');
          if (wfLocale !== targetLocale) {
            System.import('@wf-mfe/api')
              .then(({ wfetch }) =>
                wfetch(`/attask/api-internal/user/${user.ID}`, {
                  method: 'PUT',
                  body: {
                    locale: targetLocale.replace('-', '_'),
                  },
                })
              )
              .catch(updateUserError => {
                logError('Error when updating user locale from unified shell locale', {
                  userID: user.ID, targetLocale, wfLocale, error: updateUserError,
                  eimInstanceId: getEimInstanceId()
                })
              });
          }
        });
      }

      // Tells the Shell that the application is ready for users.
      // we might be able to remove this now since we have auto instantiation enabled
      const donePromise = page.done();
      logRumEvent('nwe_shell_done');

      if (donePromise instanceof Promise) {
        donePromise.then(resolveShellMetricsPromise);
      } else {
        resolveShellMetricsPromise();
      }

      performance.mark?.('nwe_shell_runtime_ready');
    });

    runtime.user.on('change:imsToken', imsToken => {
      logRumEvent('nwe_shell_imsToken_change');
      fetch('/authn/authenticate/access_token/ims?refreshable', {
        method: 'POST',
        headers: { authorization: `Bearer ${imsToken}` },
      });
    });
  }

  return Promise.resolve();
}

export function launchToLandingPage() {
  System.import('@wf-mfe/navigation')
    .then(({ navigate, workspacePayload$ }) => {
      workspacePayload$.subscribe(data => {
        const url =
          (data?.landingPage.type === 'CUSTOM'
            ? `/dashboard/${data?.landingPage.objectID}`
            : data?.landingPage.url) || '/home';

        if (url) {
          navigate(url);
        }
      });
    })
    .catch(rootNavigationError => {
      logError(rootNavigationError);
    });
}

function launchUserProfile() {
  System.import('@wf-mfe/navigation').then(({ navigate }) => {
    return getCurrentUser()
      .then(user => {
        const userProfileRoute = `/user/${user.ID}/updates`;
        navigate(userProfileRoute);
      });
  })
    .catch(profileError => {
      logError(profileError);
    });
}

async function logRumEvent(name) {
  const timing = performance.now?.() ?? 0
  const [{ logRumEvent: logEvent }, { getTreatment }] = await Promise.all([System.import('@wf-mfe/logger'), System.import('@wf-mfe/toggles')]);

  const shouldLog = await getTreatment('shell-datadog-logging');

  if (shouldLog === 'on') {
    logEvent(name, {
      [`${name}_timing`]: timing.toFixed(3),
      eimInstanceId: getEimInstanceId()
    })
  }
}
